<template>
<nav :class="[ 'family-links', active && 'family-links--on' ]">
  <div class="family-links__wrap">
    <button
      type="button"
      class="family-links__button"
      @click="active = !active">
    <span>
      <em>{{$t('base.family')}}</em>
      <Icon icon-name="arrow-down"/>
    </span>
    </button>
    <div class="family-links__dropdown">
      <ul>
        <li>
          <a href="https://www.sigongtech.co.kr" target="_blank" draggable="false">
            {{$t('family.sigongTech.title')}}
          </a>
        </li>
        <li>
          <a href="https://www.i-screammedia.com" target="_blank" draggable="false">
            {{$t('family.iceMedia.title')}}
          </a>
        </li>
        <li>
          <a href="http://sigongmunhwa.co.kr" target="_blank" draggable="false" rel="noopener">
            {{$t('family.sigongMunhwa.title')}}
          </a>
        </li>
        <li>
          <a href="https://www.littlehome-learn.com" target="_blank" draggable="false">
            {{$t('family.iceKids.title')}}
          </a>
        </li>
        <li>
          <a href="http://www.i-screamedu.co.kr" target="_blank" draggable="false">
            {{$t('family.iceEdu.title')}}
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
</template>

<script setup>
import { ref } from 'vue'
import Icon from '~/components/icon/index.vue'

const active = ref(false)
</script>

<style src="./family-links.scss" lang="scss" scoped></style>
