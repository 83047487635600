<template>
<transition name="mobile-navigation">
  <aside v-if="props.visible" class="mobile-navigation" @click="emits('close')">
    <div class="mobile-navigation__wrap" @click.stop="">
      <nav class="mobile-navigation__close">
        <button type="button" title="Close navigation" @click="emits('close')">
          <Icon icon-name="x"/>
        </button>
      </nav>
      <nav class="mobile-navigation__gnb">
        <template v-for="item in navigation.gnb">
          <a
            :href="item.link"
            :target="/^http/.test(item.link) ? '_blank' : ''"
            draggable="false"
            :class="[
              route.name === item.name && 'router-link-exact-active',
            ]">
            {{item.label}}
          </a>
        </template>
      </nav>
      <nav class="mobile-navigation__external">
        <a :href="navigation.kakaoChannel" target="_blank">
          <Picture src="/images/company/ico-mobile-navigation-002.png" alt="ch"/>
        </a>
        <a :href="navigation.instagram" target="_blank">
          <Picture src="/images/company/ico-mobile-navigation-001.png" alt="Instagram"/>
        </a>
      </nav>
    </div>
  </aside>
</transition>
</template>

<script setup>
import { onMounted, onUnmounted, watch } from 'vue'
import { navigationStore } from '~/store/navigation'
import Icon from '~/components/icon/index.vue'
import Picture from '~/components/assets/custom-picture.vue'

const route = useRoute()
const props = defineProps({
  visible: Boolean,
})
const emits = defineEmits([ 'close' ])
const navigation = navigationStore()

function controlVisible(sw)
{
  if (sw)
  {
    document.querySelector('html').classList.add('mode-modal')
  }
  else
  {
    document.querySelector('html').classList.remove('mode-modal')
  }
}

function closePanel(e)
{
  if (e.currentTarget.classList.contains('router-link-exact-active')) return
  controlVisible(false)
  emits('close')
}

onMounted(() => controlVisible(props.visible))
onUnmounted(() => controlVisible(false))
watch(() => props.visible, controlVisible)
</script>

<style src="./mobile-navigation.scss" lang="scss" scoped></style>
