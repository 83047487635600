<template>
<main class="viewport">
  <Html :lang="lang.code" :dir="lang.dir">
    <Head>
      <Title>{{$t('meta.title')}}</Title>
      <Meta name="keyword" :content="$t('meta.keywords')"/>
      <Meta name="description" :content="$t('meta.description')"/>
      <Meta name="apple-mobile-web-app-title" :content="$t('meta.title')"/>
      <Meta property="og:site_name" :content="$t('meta.title')"/>
      <Meta property="og:url" :content="navigation.pabloarts"/>
      <Meta property="og:locale" content="ko_kr"/>
      <Meta property="og:type" content="website"/>
      <Meta property="og:title" :content="$t('meta.title')"/>
      <Meta property="og:description" :content="$t('meta.description')"/>
      <Meta property="og:image" content="/images/i-scream-arts-sns.png"/>
      <Meta name="twitter:card" content="summary"/>
      <Meta name="twitter:title" :content="$t('meta.title')"/>
      <Meta name="twitter:url" :content="navigation.pabloarts"/>
      <Meta name="twitter:image" content="/images/i-scream-arts-sns.png"/>
      <Meta name="twitter:description" :content="$t('meta.description')"/>
      <Meta name="apple-mobile-web-app-capable" content="yes"/>
      <Meta name="mobile-web-app-capable" content="yes"/>
      <Meta name="theme-color" content="#fafafa"/>
      <Link rel="canonical" :content="navigation.pabloarts"/>
      <Link rel="manifest" href="/manifest.json"/>
      <Link rel="shortcut icon" href="/favicon.ico"/>
      <link rel="icon" type="image/x-icon" href="/images/pablo-arts-symbol-192.png">
      <Link rel="apple-touch-icon" href="/images/pablo-arts-symbol-512.png"/>
    </Head>
  </Html>
  <header :class="[ 'header', layout.headerTheme === 'light' && 'header--lighten' ]">
    <div class="header__wrap">
      <h1 class="header__logo">
        <a href="/" :title="$t('base.pabloarts')" class="pabloarts">
          <Logo :lang="lang.code"/>
        </a>
        <a href="https://art-bonbon.com" :title="$t('base.artbonbon')" class="bonbon">
          <img src="/images/img-logo-bonbon-light.png" alt="Art Bonbon"/>
        </a>
      </h1>
      <nav class="header__navigation">
        <template v-for="item in navigation.gnb">
          <a
            :href="item.link"
            :target="/^http/.test(item.link) ? `_blank` : ''"
            :class="[
              route.name === item.name && 'router-link-exact-active',
            ]">
            {{item.label}}
          </a>
        </template>
      </nav>
      <ChangeLocale class="header__locale"/>
      <nav class="header__toggle-button">
        <button type="button" @click="visibleMobileNavigation = true">
          <Icon icon-name="menu"/>
        </button>
      </nav>
    </div>
  </header>
  <div class="container">
    <slot/>
  </div>
  <footer class="footer">
    <div class="footer__wrap">
      <img
        src="~/assets/images/layout/img-iscreamarts.svg"
        alt="i-Scream arts Co.,Ltd."
        width="120"
        class="footer__logo">
      <nav class="footer__nav">
        <a :href="navigation.kakaoChannel" target="_blank">{{$t('base.kakaoChannel')}}</a>
        <a href="mailto:people1@i-screamarts.com">{{$t('base.contactUs')}}</a>
        <a :href="navigation.instagram" target="_blank">{{$t('base.instagram')}}</a>
        <nuxt-link to="/brand-resource">{{$t('base.brandResource')}}</nuxt-link>
        <FamilyLinks/>
      </nav>
      <div class="about footer__about">
        <address>
          {{$t('footer.address')}}<br/>
          {{$t('footer.tel')}} : <a href="tel:1833-2477">1833-2477</a><br/>
          {{$t('footer.contactUs')}} : <a href="mailto:people1@i-screamarts.com">people1@i-screamarts.com</a>
        </address>
        <p class="copyright">
          © i-Scream arts Co.,Ltd. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
  <client-only>
    <teleport to="body">
      <MobileNavigation
        :visible="visibleMobileNavigation"
        @close="visibleMobileNavigation = false"/>
    </teleport>
  </client-only>
</main>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { navigationStore } from '~/store/navigation.js'
import { layoutStore } from '~/store/layout'
import Logo from '~/components/assets/logo.vue'
import Icon from '~/components/icon/index.vue'
import MobileNavigation from '~/components/layout/mobile-navigation.vue'
import FamilyLinks from '~/components/layout/faimily-links.vue'
import ChangeLocale from '~/components/etc/change-locale.vue'

const route = useRoute()
const { locale } = useI18n()
const navigation = navigationStore()
const layout = layoutStore()
const visibleMobileNavigation = ref(false)
const lang = computed(() => {
  switch (locale.value)
  {
    case 'ko':
      return { code: 'ko-kr', dir: 'ltr' }
    case 'en':
      return { code: 'en-us', dir: 'ltr' }
    case 'vn':
      return { code: 'vi-vn', dir: 'ltr' }
  }
})

// set header theme
layout.headerTheme = (route.name === 'index') ? 'light': 'dark'
</script>

<style src="./default.scss" lang="scss" scoped></style>
