<template>
<nav
  :class="[
    'change-locale',
    context && 'change-locale--on',
  ]"
  @click.stop="">
  <div class="locale-wrap">
    <button
      v-if="!context"
      type="button"
      class="locale-button"
      @click="openContext">
    <span class="locale-body">
      <i>
        <Icon v-if="true" icon-name="locale"/>
      </i>
      <em>{{label}}</em>
    </span>
    </button>
    <ul v-if="context" class="locale-context">
      <li>
        <button
          type="button"
          :class="[ locale === 'ko' && 'on' ]"
          @click="onClickChangeLocale('ko')">
          <span class="locale-body">
            <i><Icon v-if="true" icon-name="locale"/></i>
            <em>KOR</em>
          </span>
        </button>
      </li>
      <li>
        <button
          type="button"
          :class="[ locale === 'en' && 'on' ]"
          @click="onClickChangeLocale('en')">
          <span class="locale-body">
            <i><Icon v-if="true" icon-name="locale"/></i>
            <em>ENG</em>
          </span>
        </button>
      </li>
      <li>
        <button
          type="button"
          :class="[ locale === 'vn' && 'on' ]"
          @click="onClickChangeLocale('vn')">
          <span class="locale-body">
            <i><Icon v-if="true" icon-name="locale"/></i>
            <em>VIE</em>
          </span>
        </button>
      </li>
    </ul>
  </div>
</nav>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { pressStore } from '~/store/company.js'
import { recruitStore } from '~/store/recruit.js'
import { faqStore } from '~/store/communication.js'
import Icon from '~/components/icon/index.vue'

const context = ref(false)
const { locale } = useI18n()
const press = pressStore()
const recruit = recruitStore()
const faq = faqStore()
const label = computed(() => {
  switch (locale.value)
  {
    case 'ko':
    default:
      return 'KOR'
    case 'en':
      return 'ENG'
    case 'vn':
      return 'VIE'
  }
})

async function onClickChangeLocale(key)
{
  try
  {
    if (locale.value !== key)
    {
      const { success, data } = await $fetch('/api/change-locale', {
        method: 'post',
        body: { lang: key },
      })
      if (!success) throw new Error('error')
      location.reload()
    }
    context.value = false
  }
  catch(_)
  {
    location.reload()
  }
}

function openContext()
{
  context.value = true
  window.addEventListener('click', closeContext)
}

function closeContext()
{
  context.value = false
  window.removeEventListener('click', closeContext)
}
</script>

<style src="./change-locale.scss" lang="scss" scoped></style>
