<template>
<svg xmlns="http://www.w3.org/2000/svg">
  <use :xlink:href="`${path}#icon-logo`"/>
</svg>
</template>

<script setup>
const props = defineProps({
  lang: String,
})
const path = computed(() => {
  switch (props.lang) {
    case 'ko-kr':
      return '/images/img-logo-fill-ko.svg'
    case 'en-us':
    default:
      return '/images/img-logo-fill.svg'
  }
})
</script>
